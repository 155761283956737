import type { FirebaseOptions } from 'firebase/app'
import { Environment, environment } from './environment'

const dev: FirebaseOptions = {
  apiKey: 'AIzaSyAF7JjqxSeblSAVTmThxYCBf2RFhwdPqoQ',
  appId: '1:599664883506:web:fa5f51cd88c350c34c092b',
  messagingSenderId: '599664883506',
  projectId: 'test-breakout-learning-cases',
  authDomain: 'dev.breakoutlearning.com',
  storageBucket: 'test-breakout-learning-cases.appspot.com',
  measurementId: 'G-ED4Y11818N',
}

const prod: FirebaseOptions = {
  apiKey: 'AIzaSyApc6XX-td8qnrPEi9lWH8_zO7IOrYcyyw',
  appId: '1:108325664219:web:14b077df83266b6df7a3fe',
  messagingSenderId: '108325664219',
  projectId: 'breakout-learning-cases',
  authDomain: 'app.breakoutlearning.com',
  storageBucket: 'breakout-learning-cases.appspot.com',
  measurementId: 'G-5YQZ524NKV',
}

export function getFirebaseConfig() {
  // Add ability to force production endpoint
  if (import.meta.env.VITE_USE_PRODUCTION_ENDPOINT === 'true') return prod
  if (environment() === Environment.production) return prod
  return dev
}
